.App {
	@include tablet {
		height: 100vh;
		display: flex;
		flex-direction: column;
	}
}

.editor {
	width: 100%;
	background: #eee;
	font-size: 2rem;
	caret-color: #000;
	padding: 1rem;
	outline: none;

	word-wrap: break-word;

	.active {
		background-color: yellow;
	}

	.paragraph {
		margin: 0 0 0.6em 0;
	}
}

.DraftEditor-root {
	width: 100%;
	background: #eee;
	font-size: 2rem;
	caret-color: #000;
	outline: none;

	@include mobile {
		height: 100vh;
	}
	@include tablet {
		flex: 1;
	}
}
.DraftEditor-editorContainer,
.public-DraftEditor-content {
	height: 100% !important;
}
.public-DraftEditor-content {
	padding: 5vh 6vw;

	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #cecece;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--clr-main);
		border-radius: 2px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
.align-left {
	text-align: left;
}
.public-DraftStyleDefault-block {
	margin-bottom: 1em;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

li {
	margin-left: 2rem;
}

.pictogram {
	position: absolute;
	left: 0;
	top: 1.25em;
	pointer-events: none;
	user-select: none;

	background-color: white;
	border-radius: 7.5px;
	box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06);

	display: flex;
	flex-direction: column;

	z-index: 100000000000000000;
}

.pictogram img {
	border-top-left-radius: 7.5px;
	border-top-right-radius: 7.5px;
	max-width: 130px;
	max-height: 130px;
}

.pictogram span {
	text-align: center;
}

.word:hover .pictogram {
	display: flex;
}
