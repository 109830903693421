.flex {
	display: flex;
}
.center {
	justify-content: center;
	align-items: center;
}

.column {
	flex-direction: column;
}

.f1 {
	flex: 1;
}

.wrap {
	flex-wrap: wrap;
}
