.mobile-welcome-container {
	position: fixed;
	z-index: 1000000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	.header {
		background-color: var(--clr-main);
		padding: 2vh 0;
		img {
			height: 6vh;
		}
	}
	.content {
		background: #fff;
		padding: 3vmax;
		.texts {
			gap: 5vh;
		}
		.text {
			text-align: center;
			font-size: 1.05rem;
		}

		.buttons {
			padding: 4vh 0;
			gap: 3vh;

			.button {
				width: 60vw;
				padding: 1rem 0;
				box-shadow: -14.5773px -14.5773px 43.7319px rgba(227, 227, 227, 0.3),
					14.5773px 14.5773px 43.7319px rgba(217, 217, 217, 0.5);
				border-radius: 6px;
			}
			p {
				color: #366de1;
			}
		}
	}
}
