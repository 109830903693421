.popup-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
}

.background {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: -1;
}

.popup {
	padding: 2rem;
	background-color: var(--clr-main);
	color: #fff;
	max-height: 60vh;
	width: fit-content;
	max-width: 90vw;
	align-items: center;
	&:not(.fullscreen) {
		border-radius: 8px;
	}

	&.fullscreen {
		max-width: 100vw;
		max-height: 100vh;
		width: 100vw;
		height: 100vh;
	}
}

.popup-letters {
	background-color: #eee;
	max-height: 90vh;
}

.custom-scroll {
	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #eee;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
