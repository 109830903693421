.tutorial-tooltip-container {
	background: #fff;
	padding: 1rem 2rem;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;

	max-width: 90vw;

	@include tablet {
		max-width: 35vw;
	}

	.title {
		font-weight: bold;
		font-size: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
		margin: 0 0 0.5rem 0;
	}

	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem 0 0 0;

		.btn {
			border: none;
			background-color: var(--clr-main);
			color: #fff;
			font-weight: bold;
			padding: 0.5em 0.9em;
			cursor: pointer;
			margin: 0 0.5rem;

			&-close,
			&-back {
				background-color: #fff;
				color: var(--clr-main);
				box-shadow: 0 0 0 2px var(--clr-main);
			}
		}
	}
}

.beacon-component {
	animation: pulse 2s ease-in-out infinite;
	background-color: rgba(var(--clr-main), 0.6);
	border-radius: 50%;
	display: inline-block;
	height: 2rem;
	width: 2rem;
	cursor: pointer;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	55% {
		background-color: rgba(var(--clr-main), 0.9);
		transform: scale(1.2);
	}
}
