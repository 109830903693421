.first-time-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	max-height: 200vh;
	z-index: 10000000;
	background-color: var(--clr-main);
	color: #fff;
	padding: 5vh 5vw;
	overflow-y: scroll;

	* {
		font-family: 'OpenDyslexic', sans-serif;
	}

	.welcome {
		margin: 0 0 3rem 0;
		text-align: center;
		.title {
			font-size: calc(36px + (58 - 36) * ((100vw - 300px) / (1600 - 300)));
		}

		.logo {
			width: 80vw;
			@include tablet {
				width: 30vw;
			}
		}
	}

	.privacy-policy {
		margin: 1rem 0;
		font-size: 1.2rem;

		display: block;
		position: relative;
		padding-left: 3rem;
		cursor: pointer;
		user-select: none;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		.checkmark {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			height: 2rem;
			width: 2rem;
			background-color: #eee;
			transition: 0.25s;
			display: flex;
			justify-content: center;
			align-items: center;

			&:after {
				content: '';
				position: absolute;
				display: none;
				width: 0.3rem;
				height: 0.6rem;
				border: solid white;
				border-width: 0 3px 3px 0;
				transform: translateY(-10%) rotate(45deg);
			}
		}

		&:hover input ~ .checkmark {
			background-color: #ccc;
		}

		input:checked ~ .checkmark {
			background-color: #1cca24;
		}

		input:checked ~ .checkmark:after {
			display: block;
		}
	}

	.tutorial {
		margin: 2rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
	}

	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1.2rem;
		margin: 1rem 0 0 0;

		@include mobile {
			flex-direction: column;
		}

		.btn {
			border: none;
			background-color: #fff;
			color: #26334e;
			font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
			padding: 0.6em 0.9em;
			cursor: pointer;
			border-radius: 6px;
			transition: 0.2s;

			@include mobile {
				display: block;
				width: 100%;
			}

			&:focus {
				outline: none;
				box-shadow: inset 0 0 0 2px var(--clr-main), 0 0 0px 3px #26334e;
			}
			&:hover {
				background-color: #eee;
			}
		}
	}
}
