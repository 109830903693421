.start-screen {
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

	@include mobile {
		* {
			flex-direction: column;
		}
	}

	.logo-label {
		font-size: var(--fs-small);
	}
	.logo {
		width: 55vw;
		@include tablet {
			width: 45vw;
		}
		@include desktop {
			width: 30vw;
		}
	}
	.title {
		margin: 5vh 5vw 0 5vw;
		font-size: calc(18px + (36 - 18) * ((100vw - 300px) / (1600 - 300)));
	}

	.button {
		@include tablet {
			margin: 2rem;
		}
		padding: 2rem;

		p {
			font-size: var(--fs-small);
		}
	}
}
