@mixin mobile {
	@media (max-width: 900px) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: 800px) {
		@content;
	}
}
@mixin only-tablet {
	@media (max-width: 1299px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1300px) {
		@content;
	}
}

@mixin hover {
	@include tablet {
		&:hover {
			@content;
		}
	}
}
