.donate-popup {
	background-color: #eeeeee;
	color: #121212;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 2px !important;
	max-height: 80vh;

	text-align: center;

	.buttons {
		gap: 15px;
		@include mobile {
			flex-direction: column;
		}
	}

	.big-button {
		padding: 2vmax;
		gap: 3vh;
		img {
			height: 16vh;
		}

		.button {
			padding: 0.5rem 0.8rem;
			background: var(--clr-main);
			color: #fff;
			text-decoration: none;
			display: block;
			border-radius: 6px;
			transition: 0.2s;

			@include hover {
				background-color: var(--clr-main-light);
			}
		}
	}
}
