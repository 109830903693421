.topbar {
	background-color: #eeeeee;
	z-index: 100;
	display: flex;
	flex-direction: column;

	@include tablet {
		position: sticky;
		top: 0;
	}

	.content-container {
		padding: 0 var(--padding-sides);
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgb(223, 223, 223);
		.content {
			display: flex;
			width: 100%;
			align-items: center;
			color: #26334e;
			flex-wrap: wrap;

			@include mobile {
				justify-content: center;
			}
		}
	}

	.item {
		display: flex;
		margin: 1rem 2rem 1rem 0;
		text-decoration: none;
		@include mobile {
			flex-direction: column;
			align-items: center;
			margin: 0.3rem;
			padding: 1rem;
		}
	}

	.icons {
		gap: 1vw;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		svg {
			cursor: pointer;
		}
	}

	.input-group {
		display: flex;
		flex-direction: column;
		label {
			font-size: var(--fs-xsmall);
			margin: 0 0 0.2rem 0;
		}

		input:not(input[type='range']),
		select {
			font-size: 16px;
			padding: 0.5em;
			width: 5em;
			border-radius: 4px;
			border: none;
		}

		input[type='range'] {
			-webkit-appearance: none;
			width: 100%;
			height: 10px;
			border-radius: 5px;
			background: #fff;
			outline: none;
			margin: 0.3rem 0;

			@include mobile {
				margin: 0.3rem 0 1rem 0;
			}

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: var(--clr-main);
				cursor: pointer;
			}
		}

		select {
			width: 10em;
			cursor: pointer;
			transition: 0.2s;

			@include hover {
				background: rgb(214, 214, 214);
			}

			option {
				background: #fff;
			}
		}
	}

	.buttons-input {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		border-radius: 6px;

		input {
			flex: 1;
			border: none;
			width: 5em;
			padding: 0.3em;
			text-align: center;
			color: #26334e;
			height: 100%;

			&::-webkit-outer-spin-button,
			.tutorial-tooltip-container {
				display: none;
			}
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		button {
			border: none;
			background: #fff;
			font-weight: bold;
			font-size: 1.5rem;
			padding: 0.5rem;
			cursor: pointer;
			color: #757575;
			transition: 0.2s;

			&:hover {
				background: rgb(214, 214, 214);
			}

			&.btn-left {
				border-right: 2px solid #dadada;
				border-top-left-radius: inherit;
				border-bottom-left-radius: inherit;
			}
			&.btn-right {
				border-left: 2px solid #dadada;
				border-top-right-radius: inherit;
				border-bottom-right-radius: inherit;
			}
		}
	}

	.voice-speed {
		min-width: 10vw;
		margin: 0 1rem;
	}
}

.icon-button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #26334e;
	cursor: pointer;
	position: relative;

	p {
		margin: 6px 0 0 0;

		font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
	}
}

.paste-error {
	@include tablet {
		position: absolute;
		bottom: 0;
		transform: translateY(120%);
	}
	@include mobile {
		margin: 1rem 0 0 0;
	}
	box-sizing: border-box;
	width: 100%;
	padding: 1rem;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.2);
	font-size: var(--fs-xsmall);
}

.tabs {
	overflow-x: scroll;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 var(--padding-sides);
	background-color: var(--clr-main);
	z-index: 100;

	&::-webkit-scrollbar {
		display: none;
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.logo {
		@include mobile {
			display: none;
		}
		height: 40px;
		margin: 0 2vw 0 0;
	}

	.tab {
		padding: 1rem 1.5vw;
		cursor: pointer;

		font-size: var(--fs-xsmall);
		color: #fff;
		transition: 0.2s background-color;

		&.active {
			background-color: #eeeeee;
			color: var(--clr-main);
		}
		&:not(.active) {
			@include hover {
				background-color: var(--clr-main-dark);
			}
		}
	}

	.donate-button {
		background: #ec5484;
		color: #fff;
		padding: 0.4rem 1.4vw;
		margin: 0 0.6rem 0 auto;
		border-radius: 6px;
		cursor: pointer;

		@media screen and (max-width: 600px) {
			margin: 0 0.6rem;
		}
	}
}
.rcp {
	--rcp-background: #eeeeee !important;
}
.button-hover,
.icon-button {
	transition: background-color 0.2s;
	border-radius: 4px;
	padding: 0.3rem 1rem;
	cursor: pointer;

	@include mobile {
		padding: 0.3rem;
	}

	@include hover {
		background-color: rgba(0, 0, 0, 0.15);
	}
}
