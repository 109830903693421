.thanks-popup {
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90vh;

	.link-container {
		width: clamp(200px, 80vw, 800px);
		margin: 2vh 0;
		padding: 0 1rem;
	}

	a {
		align-items: center;
		gap: 5px;
		color: #fff;
		font-size: 20px;
	}
}
