.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background-color: #ccc;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 90000;
}
