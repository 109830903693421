:root {
	--fs-xsmall: calc(16px + (18 - 16) * ((100vw - 300px) / (1600 - 300)));
	--fs-small: calc(18px + (22 - 18) * ((100vw - 300px) / (1600 - 300)));
	--fs-medium: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300)));
	--fs-large: calc(36px + (50 - 36) * ((100vw - 300px) / (1600 - 300)));

	--padding-sides: 2vw;

	--clr-main: #5286f4;
	--clr-main-dark: hsl(221, 88%, 62%);
	--clr-main-light: hsl(221, 88%, 68%);
}

$headerSize: 20vh;
