.keyboard-base {
	border-radius: 10px;
	display: grid;
	grid-template-columns: repeat(30, 30px);
	grid-template-rows: repeat(5, 60px);
	gap: 3px;

	@media screen and (max-width: 1100px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;
	}
}

.change-all-letters button {
	background: rgb(196, 196, 196);
	border: none;
	padding: 1.2rem 1.6rem;
	border-radius: 6px;
	margin: 3vh 0 0 0;
	color: rgb(15, 15, 15);
	font-size: 20px;
	cursor: pointer;
}

.close-btn {
	align-self: flex-end;
	margin: 0 0 2vh 0;
	font-size: 30px;
	cursor: pointer;
	color: #1b1b1b;
}

.key {
	//border: 2px solid black;
	grid-column: span 2;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	position: relative;
	font-size: 20px;

	@media screen and (max-width: 1100px) {
		padding: 1em;
	}

	button {
		cursor: pointer;
		font-size: 20px;
		display: block;
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		transition: 0.1s;
		background: rgb(196, 196, 196);

		@include hover {
			background-color: rgb(199, 199, 199);
		}
	}
}

.inactive {
	background-color: rgb(148, 148, 148);
	pointer-events: none;

	@media screen and (max-width: 1100px) {
		display: none;
	}
}

.delete {
	grid-column: span 4;
}

.tab {
	grid-column: span 3;
}

.backslash {
	grid-column: span 3;
}

.capslock {
	grid-column: span 4;
}

.return {
	grid-column: span 4;
}

.leftshift {
	grid-column: span 5;
}

.rightshift {
	grid-column: span 5;
}

.leftctrl {
	grid-column: span 3;
}

.command {
	grid-column: span 3;
}

.space {
	grid-column: span 13;
}

.color-button {
	cursor: pointer;
}

.color-picker-container *,
.color-picker-container {
	cursor: default;
}
