.loader {
	margin: 5vh auto 0 auto;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 6px solid var(--clr-main);
	border-color: var(--clr-main) var(--clr-main) var(--clr-main) transparent;

	animation: spin 1s infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
